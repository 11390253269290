<template>
  <div class="page-content" v-if="user">
    <page-breadcrumb
      title="User Detail"
      :items="breadcrumbItems"
    />
    <b-card class="mt-3">
      <!-- edit buttons -->
      <div class="d-flex justify-content-end">
        <delete-button v-if="userData.role === 'admin'" class="btn-icon ml-50" variant="relief-danger" @delete="suspendUser"
          v-b-tooltip.hover="'suspend user'"
        >
          <b-spinner v-if="isLoading" small />
          <feather-icon v-else icon="SlashIcon" />
        </delete-button>
      </div>
      <!-- edit buttons end -->

      <b-media class="mt-1">
        <template #aside>
          <b-avatar
            rounded
            size="60"
            :src="user.avatar"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          >
            <feather-icon
              v-if="!user.email"
              icon="UserIcon"
              size="60"
            />
          </b-avatar>
        </template>
        <b-card-text class="d-flex">
          <div class="mr-3">
            <template>
            <b>Email: {{ user.email }}</b>
            </template>
            <span class="mt-1" style="display: block">Name: {{ user.name }}</span>
          </div>
        </b-card-text>
      </b-media>
      <b-row>
        <b-col cols="6">
          <h5 class="mb-75 mt-2">Status: <b-badge :variant="userStatus.variant">{{ userStatus.text }}</b-badge></h5>
          <h5 class="mb-75 mt-2">Language: {{ user.language }}</h5>
          <h5 class="mb-75 mt-2">Country: {{ user.country }}</h5>
          <h5 class="mb-75 mt-2">Current Level: {{ user_level ? user_level.progress_mark : 0 }}</h5>
        </b-col>
      </b-row>
      
      <!-- login devices -->
      <div class="row">
        <div class="col-8">
          <div class="mt-2">
            <h5 class="mb-75">Device:</h5>
          </div>
        </div>
        <div class="col-2">
          <div class="mt-2">
            <h5 class="mb-75">First login at</h5>
          </div>
        </div>
        <div class="col-2">
          <div class="mt-2">
            <h5 class="mb-75">Login device</h5>
          </div>
        </div>
      </div>
      <div class="row" v-for="device in login_devices" :key="device._id">
        <div class="col-8">
          <div class="mt-1">
            {{ getLoginDevice(device) }}
          </div>
        </div>
        <div class="col-2">
          <div class="mt-1">
            {{ device ? device.createdAt : '' | local_time_string }}
          </div>
        </div>
        <div class="col-2">
          <div class="mt-1">
            {{ device.registration_id ? 'Active' : 'Inactive' }}
          </div>
        </div>
      </div>
      <!-- login devices end -->
    </b-card>
    <!-- Iap purchase -->
    <b-card>
      <data-table-ssr id="group_list" ref="group_list"
        :columns="fields" :get-list-fn="getIapRecord"
      >
        <template #slot_price="{slotData}">
          {{ slotData.products.price }}
        </template>
        <template #slot_currency="{slotData}">
          {{ slotData.products.currency }}
        </template>
        <template #slot_products="{slotData}">
          <p style="white-space: pre-line;">{{ formatProduct(slotData.products) }}</p>
        </template>
      </data-table-ssr>
    </b-card>
    <action-buttons back-button />
  </div>
</template>

<script>
const fields = [
  { label: 'Platform', field: 'platform' },
  { label: 'Product Type', field: 'productType' },
  { label: 'Test Transaction', field: 'test_transation', value_handler: (model)=>{return model.productType ? 'TRUE' : 'FALSE'} },
  { label: 'Store Product Id', field: 'store_product_id' },
  { label: 'Price', field: 'price', input_type: 'slot' },
  { label: 'Currency', field: 'currency', input_type: 'slot' },
  { label: 'Created At', field: 'createdAt', input_type: 'datetime' },
  { label: 'Device', field: 'device' },
  { label: 'Products', field: 'products', input_type: 'slot' },
];
import service from "../service";
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import {STATUS} from './../constants.js';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      user: null,
      user_products: null,
      login_devices: null,
      user_level: null,
      userEmail: null,
      userPassword: null,
      iap_records: null,
      isLoading: false,
      fields
    };
  },
  computed: {
    userId() {
      return this.$route.params.user_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "User List",
          to: { name: "user-list" },
        },
      ];
      items.push({ text: "User Detail", active: true });
      return items;
    },
    userStatus() {
      let status = STATUS.find(item => item.value === this.user.status);
      return status || { text: 'unknown' };
    },
    userData() {
      return this.$store.getters['auth/userData']
    },
  },
  async created() {
    this.get();
    this.getLoginDevice();
    this.getIapRecord();
  },
  methods: {
    async get() {
      let response = await service.get({ id: this.userId });
      if (response) {
        this.user = response.user;
        this.user_products = response.user_products;
        this.login_devices = response.login_devices;
        this.userEmail = this.user?.email;
        this.user_level = response.user_level;
      }
    },

    async suspendUser() {
      this.isLoading = true
      const response = await service.suspend({ id: this.userId })
      if (response) {
        this.get()
      }
      this.isLoading = false
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    getLoginDevice(device) {
      if (!device || !device.info) {
        return ''
      }
      if (device.info[0] !== '{') {
        return device.info
      }
      let split = device.info.split("brand':'")[1]
      split = split.slice(0,split.length - 2).replace("','os':'", ', ')

      return split
    },

    async getIapRecord({ limit, page, query }) {
      let response_data = await service.getIapRecord({
        query: JSON.stringify({ ...query, user_id: this.user._id }),
        page, limit
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    formatProduct(product){
        delete product.price;
        delete product.currency;
        let formattedProduct = '';
        for (const key in product) {
          if (product[key] != 0){
            formattedProduct += `${key.replace('_', ' ')}: ${product[key]} \n`;
          }
        }
        return formattedProduct;
    }
  },
};
</script>
